.bookingCard{
  // flex: 1;
  width: 100%;
  max-width: 300px;
  height: 450px;

  padding: 3.5rem 2rem; 
  background-color: rgba($color: #FFF, $alpha: 0.1);
  border-radius: 1rem;

  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: fl;
  
  // border: 4px solid red;
  &__back__number{
    position: absolute;
    color: #FFF;
    opacity: 0.05;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 200px;
    line-height: 190px;
    top: 0;
    right: 10px;
    
  }
  &__body{
    width: 100%;
    flex: 1;
    padding: 3rem 0 2rem 0;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // border: 4px solid red;

    &__description{
      text-align: center !important;
      // margin-bottom: 5rem;
    }
    
    &__price{
      font-size: 44px;
      text-align: center;
    }
  }
  &__book__button{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50px;
    background-color: #1769FF;
    border: none;
    outline: none;
    cursor: pointer;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    transition: all 0.3s ease-in-out;
    &:hover{
      background-color: rgba($color: #1769FF, $alpha: 0.8);
    }
  }
}