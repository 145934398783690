.calendarWrapper {
  color: white;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: black;

  // overflow-x: hidden;
  gap: 2rem;
  @media screen and (max-width: 1080px) {
    height: 100%;
    padding: 5rem 2rem;
    // height : 1200px
  }
  @media screen and (max-width: 360px) {
    padding: 5rem 0rem;
    // height : 1200px
  }
  &__content {
    max-width: 1000px;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // justify-content: center;
    // gap: 2rem;

    display: grid;
    @media screen and (min-width: 1080px) {
      grid-template-columns: auto 325px;
      grid-template-rows: auto 1fr;
    }
    row-gap: 2rem;
    column-gap: 2rem;
    position: relative;

    .back {
      position: absolute;
      top: 0;
      left: calc(-50px - 1rem);
      width: 50px;
      height: 50px;
      background-color: rgba($color: #fff, $alpha: 0.1);
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 1.5rem;

      // opacity: 0.5;
      // z-index: 1;
    }

    @media screen and (max-width: 1200px) {
      padding-top: 5rem;
      .back {
        top: 0;
        left: 0;
      }
    }

    &__browserClassified {
      // flex: 1;
      display: flex;
      flex-direction: column;
      // gap: 2rem;
      &__title {
        margin-bottom: 1rem;
      }
      &__body {
        opacity: 0.6;
      }
    }

    &__ticket {
      // height: 100%;
      // min-width: 315px;
      // max-width: 315px;
      // flex-grow: 1;

      // width: 315px !important;
      grid-column: 2/3;
      grid-row: 1/3;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      // flex: 1;
      // height: 100%;
      width: 315px;
      padding-bottom: 60px;
      position: relative;
      // overflow: hidden;

      &__title {
        text-align: center;
        margin-bottom: 2rem;
      }
      &__body {
        text-align: center;
      }
      &__infoWrapper {
        padding-block: 2rem;
        // margin: auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .select_date {
          opacity: 0.5;
        }
        .selected_date {
          opacity: 0.5;
        }
        .price {
          // margin-top: 1rem;
          font-size: 44px;
        }
      }
      &__continueButton {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        // margin: 0 auto;
        background-color: #1769ff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 315px;
        height: 60px;

        border: 0;
        outline: 0;

        border-radius: 0 0 1rem 1rem;
        // overflow: hidden;
        &:hover {
          background-color: #1769ff;
        }
        &.disabled {
          background-color: #1769ff;
          opacity: 0.5;
        }
      }
    }
    @media screen and (max-width: 1080px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;

      &__ticket {
        grid-column: auto;
        grid-row: auto;

        // flex-direction: row;
        // align-items: flex-start;
        width: 100%;
        * {
          text-align: left;
        }
        // .calendarWrapper_content_ticket_header {
        //   max-width: 400px;
        // }

        @media screen and (min-width: 650px) {
          &__infoWrapper {
            // border: 1px solid red;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
          }
        }

        &__continueButton {
          width: 100%;
          position: relative;
          // margin: 0 auto;
          background-color: #1769ff;
          display: flex;
          align-items: center;
          justify-content: center;
          // width: 100px;
          // height: 100px;

          border-radius: 1rem;
          // overflow: hidden;
          &.disabled {
            background-color: #1769ff;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
