@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
$fontFamily: Inter, "Arial", "Helvetica", sans-serif;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  user-select: none;
}

body {
  background-color: black;
  font-family: $fontFamily;
  // width: 100vw;
  // overflow: hidden;
  // overflow-x: hidden;
}

.ts1 {
  font-family: $fontFamily;
  font-size: 2rem;
  font-weight: 700;
  color: white;
}

.ts2 {
  font-family: $fontFamily;
  font-size: 1.25rem;
  color: white;
  text-align: justify;
}

.ts3 {
  font-family: $fontFamily;
  font-size: 1.25rem;
  font-weight: 700;
  color: white;
}

a {
  text-decoration: none;
  color: black;
}
