.checkoutPage__wrapper {
  color: white;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: black;

  overflow-x: hidden;
  gap: 2rem;
  @media screen and (max-width: 1080px) {
    height: 100%;
    padding: 5rem 2rem;
    // height : 1200px
  }
  @media screen and (max-width: 360px) {
    padding: 5rem 0rem;
    // height : 1200px
  }
  .checkoutPage__content {
    max-width: 1000px;

    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // justify-content: center;
    // gap: 2rem;

    display: grid;
    grid-template-columns: auto 325px;
    grid-template-rows: auto 1fr;
    row-gap: 2rem;
    column-gap: 2rem;
    position: relative;

    &__congrats {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      text-align: center;
      @media screen and (min-width: 1080px) {
        width: 700px;
      }

      &__title:first-of-type {
        padding-block: 3rem;
        font-size: 2rem;
      }

      p {
        margin-block: 1rem;
        color: rgba($color: #fff, $alpha: 0.6);
        font-size: 1rem;
      }

      &__title {
        font-size: 1.5rem;
        max-width: 380px;
      }

      .continueToHomePage {
        height: 50px;
        border-radius: 1rem;
        background-color: #1769ff;
        padding: 1rem 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bolder;
        font-size: 1rem;

        margin-top: 3rem;
      }
    }

    &__ticket {
      grid-column: 2/3;
      grid-row: 1/3;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      width: 315px;

      &__title {
        text-align: center;
      }
      &__body {
        text-align: center;
      }
      &__infoWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .select_date {
          opacity: 0.5;
        }
        .selected_date {
          opacity: 0.5;
        }
        .price {
          font-size: 44px;
        }
      }
    }
    @media screen and (max-width: 1080px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;

      &__ticket {
        grid-column: auto;
        grid-row: auto;

        width: 100%;
        * {
          text-align: left;
        }

        @media screen and (min-width: 650px) {
          &__infoWrapper {
            // border: 1px solid red;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
          }
        }

        &__continueButton {
          width: 100%;
          position: relative;
          // margin: 0 auto;
          background-color: #1769ff;
          display: flex;
          align-items: center;
          justify-content: center;
          // width: 100px;
          // height: 100px;

          border-radius: 1rem;
          overflow: hidden;
          &.disabled {
            background-color: #1769ff;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
