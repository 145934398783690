.primaryCardWrapper {
  width: 100%;
  max-width: 1000px;

  padding: 2rem;
  background-color: rgba($color: #fff, $alpha: 0.1);
  border-radius: 1rem;

  @media screen and (max-width: 1080px) {
    padding: 1.5rem;
  }
  @media screen and (max-width: 500px) {
    padding: 1rem;
  }
}
