.mainCalendarWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  @media screen and (max-width: 400px) {
    width: calc(100vw - 2rem);
    overflow-x: scroll;
  }
  // overflow-x: scroll;

  //! variables
  --size: 50px;
  --border-radius: 1rem;
  --gap: 1rem;
  @media screen and (max-width: 550px) {
    --size: 40px;
    --border-radius: 0.7rem;
    --gap: 0.5rem;
  }
  //! variables

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    // aspect-ratio: 1 / 1;
    .days {
      // width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: calc(var(--gap) + 0.5rem);
      &__day {
        width: var(--size);
        height: var(--size);

        // make text uppercase;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;

        &.deactivated {
          opacity: 0.5;
        }
        &:not(:last-child) {
          margin-right: var(--gap);
        }
      }
    }
    .mainCalendar {
      // width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;

      &__row {
        // width: 100%;
        display: flex;
        align-items: center;
        // justify-content: space-evenly;

        margin-bottom: var(--gap);

        .mainCalendar__day {
          font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
          width: var(--size);
          height: var(--size);
          background-color: #1769ff;
          border-radius: var(--border-radius);
          border: 0;
          outline: 0;

          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          // font-weight: 700;

          font-size: 1rem;
          &:hover {
            background-color: #0d47a1;
          }
          &.deactivated {
            cursor: not-allowed;
            // opacity: 0.5;
            background-color: rgba($color: #fff, $alpha: 0.1);
            color: rgba($color: #fff, $alpha: 0.6);
            &:hover {
              background-color: rgba($color: #fff, $alpha: 0.1);
            }
          }
          &.booked {
            cursor: not-allowed;
            background-color: rgba($color: #fff, $alpha: 0.1);
            color: rgba($color: #fff, $alpha: 0.6);
            opacity: 0.5;
            &:hover {
              background-color: rgba($color: #fff, $alpha: 0.1);
            }
          }
          &.selected {
            background-color: white;
            color: #1769ff;
            &:hover {
              background-color: white;
            }
          }
          &:not(:last-child) {
            margin-right: var(--gap);
          }
          // &:not(.deactivated),
          // &:not(.booked),
          // &:not(.selected) {

          // }
        }
      }
    }
  }
}
