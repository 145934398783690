.homeWrapper {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: black;

  // overflow-x: hidden;
  @media screen and (max-width: 1080px) {
    height: 100%;
    padding: 5rem 2rem;
    // height : 1200px
  }
  @media screen and (max-width: 360px) {
    padding: 5rem 0rem;
    // height : 1200px
  }
  &__header {
    @media screen and (max-width: 360px) {
      max-width: 300px;
      // height : 1200px
    }
    &__title {
      margin-bottom: 1rem;
    }
    &__body {
      opacity: 0.6;
    }
  }
  &__body {
    width: 100vw;
    max-width: 1000px;
    margin-top: 2rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1080px) {
      flex-direction: column;
      height: calc(1450px + 2rem);
    }
  }
}
