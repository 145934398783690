.formPageWrapper {
  display: flex;
  justify-content: center;
  padding: 1rem;
  height: 100vh;
  .formWrapper {
    width: 100%;
    height: 100%;
    max-width: 600px;
    padding: 1rem;
    .formWrapperPrimaryCard {
      display: flex;
      align-items: center;
      // justify-content: center;
      flex-direction: column;
      .infoWrapper {
        margin-bottom: 2rem;
        width: 100%;
        h1 {
          margin-bottom: 1rem;
        }
        table {
          tr {
            font-size: 1rem;
            td:not(:last-child) {
              padding-right: 1rem;
            }
          }
        }
      }
      .linkWrapper {
        display: flex;
        // align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 1rem;
        width: 100%;
        margin-bottom: 2rem;
        .linkLabel {
          font-size: 1rem;
          margin-bottom: 1rem;
          span {
            opacity: 0.5;
          }
        }
        .linkInput {
          outline: none;
          width: 100%;
          padding: 10px 1rem;

          background-color: transparent;

          border: 2px solid rgba($color: #fff, $alpha: 0.1);
          border-radius: 10px;

          color: #fff;
          font-size: 1rem;
        }
      }
      .bodyWrapper {
        display: flex;
        // align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 1rem;
        width: 100%;
        margin-bottom: 1rem;
        .bodyLabel {
          font-size: 1rem;
          margin-bottom: 1rem;
          span {
            opacity: 0.5;
          }
        }
        .bodyInput {
          outline: none;
          width: 100%;
          max-width: 100%;
          min-width: 100%;
          min-height: 100px;
          padding: 10px 1rem;

          background-color: transparent;

          border: 2px solid rgba($color: #fff, $alpha: 0.1);
          border-radius: 10px;

          color: #fff;
          font-size: 1rem;
        }
        .bodyCharactersCount {
          margin-top: 0.5rem;
          text-align: right;
          opacity: 0.5;
          font-size: 1rem;
          // span {
          // }
        }
      }

      .infoNoteWrapper {
        margin-bottom: 2rem;
        width: 100%;

        font-size: 1rem;
        opacity: 0.5;
      }

      .submitWrapper {
        width: 100%;
        button {
          width: 100%;
          padding: 0.8rem 1rem;
          background-color: #1769ff;
          color: #fff;
          border: none;
          border-radius: 10px;
          font-size: 1rem;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}
